exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-about-us-jsx": () => import("./../../../src/pages/about-us.jsx" /* webpackChunkName: "component---src-pages-about-us-jsx" */),
  "component---src-pages-blog-jsx": () => import("./../../../src/pages/blog.jsx" /* webpackChunkName: "component---src-pages-blog-jsx" */),
  "component---src-pages-case-studies-jsx": () => import("./../../../src/pages/case-studies.jsx" /* webpackChunkName: "component---src-pages-case-studies-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-jobs-jsx": () => import("./../../../src/pages/jobs.jsx" /* webpackChunkName: "component---src-pages-jobs-jsx" */),
  "component---src-pages-services-application-development-jsx": () => import("./../../../src/pages/services/application-development.jsx" /* webpackChunkName: "component---src-pages-services-application-development-jsx" */),
  "component---src-pages-services-cloud-infrastructure-jsx": () => import("./../../../src/pages/services/cloud-infrastructure.jsx" /* webpackChunkName: "component---src-pages-services-cloud-infrastructure-jsx" */),
  "component---src-pages-services-cybersecurity-jsx": () => import("./../../../src/pages/services/cybersecurity.jsx" /* webpackChunkName: "component---src-pages-services-cybersecurity-jsx" */),
  "component---src-pages-services-data-ai-jsx": () => import("./../../../src/pages/services/data-ai.jsx" /* webpackChunkName: "component---src-pages-services-data-ai-jsx" */),
  "component---src-pages-services-enterprise-applications-jsx": () => import("./../../../src/pages/services/enterprise-applications.jsx" /* webpackChunkName: "component---src-pages-services-enterprise-applications-jsx" */),
  "component---src-pages-services-networking-jsx": () => import("./../../../src/pages/services/networking.jsx" /* webpackChunkName: "component---src-pages-services-networking-jsx" */),
  "component---src-pages-services-project-management-jsx": () => import("./../../../src/pages/services/project-management.jsx" /* webpackChunkName: "component---src-pages-services-project-management-jsx" */),
  "component---src-pages-services-quality-engineering-jsx": () => import("./../../../src/pages/services/quality-engineering.jsx" /* webpackChunkName: "component---src-pages-services-quality-engineering-jsx" */)
}

